import Cookies from "js-cookie";
import { Modal } from "@components/Modal/Modal";
import styles from "./styles/newsletter.module.css";

export function Newsletter({ modal, subscribed }) {
  if (modal === false) {
    return null;
  }

  if (Cookies.get("klaviyo_modal_subscribed") || Cookies.get("modal_closed")) {
    return null;
  }

  if (subscribed) {
    return null;
  }
  const counter = Cookies.get("klaviyo_modal_viewed_counter");

  if (counter && parseInt(counter) >= 3) {
    return null;
  }

  if (modal) {
    return (
      <div className={styles.container}>
        <Modal
          description={modal.descriptionCopy}
          thankYou={modal.thankYouCopy}
          disclaimer={modal.disclaimerCopy}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Modal />
    </div>
  );
}
