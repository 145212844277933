import PropTypes from "prop-types";
import Link from "next/link";
import { getLink } from "@lib/getLink";

import { A } from "@components/Anchor";

function Cta({ navLink, underline = false, isExternal = false, onMouseEnter, onMouseLeave }) {
  const { handle, link, slug } = navLink;

  const formattedLink = navLink ? getLink(navLink) : {};

  // Handle linking to a shopify collection or product
  if (handle) {
    return (
      <Link
        href={{ pathname: formattedLink.href, query: formattedLink.query }}
        passHref
        prefetch={false}
      >
        <A underline={underline} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {formattedLink.text}
        </A>
      </Link>
    );
  }

  // handle linking to a nextJS page
  if (slug) {
    return (
      <Link href={formattedLink.href} passHref prefetch={false}>
        <A underline={underline} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {formattedLink.text}
        </A>
      </Link>
    );
  }

  // handle external link
  if (link) {
    return (
      <A
        href={formattedLink.href}
        underline={underline}
        isExternal={isExternal || formattedLink.blank}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {formattedLink.text}
      </A>
    );
  }

  // default to an inline button when all else fails

  return formattedLink.href ? (
    <Link href={formattedLink.href} passHref prefetch={false}>
      <A underline={underline} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {formattedLink.text}
      </A>
    </Link>
  ) : (
    <A underline={underline} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {formattedLink.text}
    </A>
  );
}

Cta.propTypes = {
  navLink: PropTypes.shape({
    title: PropTypes.string,
    refTitle: PropTypes.string,
    handle: PropTypes.string,
    link: PropTypes.object,
    slug: PropTypes.object,
    type: PropTypes.string,
  }),

  underline: PropTypes.bool,
};

export default Cta;
