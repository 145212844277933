import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";
import { Checkbox } from "./Checkbox/Checkbox";
import styles from "./styles/form.module.css";

export function SubscribeFormCheckboxes({ values, womenKey, menKey, homeKey, handleChange }) {
  const { t } = useTranslation("common");
  const MENS = t("men-s");
  const WOMENS = t("women-s");
  const HOME = t("home");

  return (
    <div className={styles.checkboxSection}>
      <label htmlFor={womenKey}>
        <Checkbox
          id={womenKey}
          name={womenKey}
          value="women"
          checked={values[womenKey]}
          onChange={handleChange}
        />
        {WOMENS}
      </label>
      <label htmlFor={menKey}>
        <Checkbox
          id={menKey}
          name={menKey}
          value="men"
          checked={values[menKey]}
          onChange={handleChange}
        />
        {MENS}
      </label>
      <label htmlFor={homeKey}>
        <Checkbox
          id={homeKey}
          name={homeKey}
          value="home"
          checked={values[homeKey]}
          onChange={handleChange}
        />
        {HOME}
      </label>
    </div>
  );
}

SubscribeFormCheckboxes.propTypes = {
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  womenKey: PropTypes.string.isRequired,
  menKey: PropTypes.string.isRequired,
  homeKey: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
