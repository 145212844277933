import Link from "next/link";
import styles from "./styles/alert.module.css";

export function Alert() {
  return (
    <aside className={styles.preview}>
      <span className={styles.column}>This page is a preview draft</span>
      <span className={styles.column}>
        <Link href="/api/exit-preview">
          <a>Click here to exit preview mode</a>
        </Link>
      </span>
    </aside>
  );
}
