import { useRouter } from "next/router";
import styles from "./styles/toggle.module.css";

export function LanguageToggle() {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const changeLocale = (nextLocale) => {
    // change just the locale and maintain all other route information including href's query
    router.push({ pathname, query }, asPath, { locale: nextLocale });
  };
  return (
    <fieldset className={styles.container}>
      <legend>Language Selector</legend>
      <input
        type="radio"
        id="radio-en"
        name="language"
        value="English"
        checked={locale === "en"}
        onChange={() => changeLocale("en")}
      />
      <label htmlFor="radio-en">EN</label>
      <input
        type="radio"
        id="radio-fr"
        name="language"
        value="French"
        onChange={() => changeLocale("fr")}
        checked={locale === "fr"}
      />
      <label htmlFor="radio-fr">FR</label>
    </fieldset>
  );
}

LanguageToggle.propTypes = {};
