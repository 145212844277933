import { useState } from "react";
import { useRouter } from "next/router";
import { NavBarLeft } from "./NavBarLeft";
import { NavBarRight } from "./NavBarRight";
import { NavBarCenter } from "./NavBarCenter";
import { NavBarWrapper } from "./NavBarWrapper";
import { MobileDrawer } from "./MobileDrawer";
import { useMobileDetect } from "../../../hooks";
import { Drawer } from "./Drawer";
import { KotnSupplyQuoteButton } from "@components/KotnSupply/KotnSupplyQuoteDrawer";

export function NavBar({ navigation, logo, brandStory }) {
  const { locale } = useRouter();
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [navList, setNavList] = useState(null);
  const toggleShowMobileDrawer = () => setShowMobileDrawer(!showMobileDrawer);

  const isFrench = locale === "fr";
  const frNavigation = navigation.filter(item => item.link?.href !== "https://kotn.com/editorials");

  const setNavigationItems = (item) => {
    setNavList(item);
    setShowDrawer(item && item.menus ? true : false);
    item ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
  };

  const toggleShowDrawer = (bool) => {
    setShowDrawer(bool);
    bool ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
  };

  return (
    <>
      <NavBarWrapper toggleDrawer={toggleShowDrawer}>
        <NavBarLeft
          navigation={isFrench ? frNavigation : navigation}
          handleShowMobileDrawer={toggleShowMobileDrawer}
          handleShowDrawer={setNavigationItems}
          toggleDrawer={toggleShowDrawer}
          showDrawer={showDrawer}
          navList={navList}
        />
        <NavBarCenter logo={logo} />
        <NavBarRight />
        <MobileDrawer
          navigation={isFrench ? frNavigation : navigation}
          showMobileDrawer={showMobileDrawer}
          toggleShowMobileDrawer={toggleShowMobileDrawer}
        />
        <Drawer
          navList={navList}
          showDrawer={showDrawer}
          toggleDrawer={toggleShowDrawer}
          brandStory={brandStory}
        />
      </NavBarWrapper>
      {isMobile && (
        <KotnSupplyQuoteButton />
      )}
    </>
  );
}
