/* eslint-disable react/no-unescaped-entities */
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import { BCorpLogo } from "@icons/logo-bcorp";
import { useTranslation } from "next-i18next";
import { default as Cta } from "../Cta";
import { SubscribeForm } from "../Modal/SubscribeForm";
import styles from "./styles/footer.module.css";

const Footer = ({ navItems }) => {
  const [profileID, setProfileID] = useState(null);
  const [thankYouMessage, setthankYouMessage] = useState(false);
  const extoleRef = useRef();
  const { t } = useTranslation("footer");

  const SIGN_UP = t("sign-up");
  const THANKYOU = t("thankyou");
  // const REFER = t("refer");

  useEffect(() => {
    window.extole?.createZone({ name: "global_header", element: extoleRef.current });
  }, []);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("utm_profileid");
    if (id) setProfileID(id);
  }, []);

  const menus = navItems?.menus;
  !menus && null;

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <p className={styles.header}>{navItems.title}</p>
        {navItems.formTitle && <p className={styles.formTitle}>{navItems.formTitle}</p>}
        <article className={styles.form}>
          <SubscribeForm
            listID="PR4WMT"
            formSource="Footer"
            buttonLabel={`${SIGN_UP} →`}
            profileID={profileID}
            setthankYouMessage={setthankYouMessage}
          />
          {thankYouMessage && <p className={styles.message}>{THANKYOU}</p>}
        </article>
        <nav>
          {menus?.map((menu) => {
            const { links } = menu;
            return (
              <ul className={styles.links} key={menu.key}>
                {links?.map((navLink) => {
                  return (
                    <li key={navLink.key} className={styles.link}>
                      <Cta key={navLink.key} navLink={navLink} />
                    </li>
                  );
                })}
                {/* {title === "Other" && (
                  <span id="extole_zone_global_footer" className={styles.link} ref={extoleRef}>
                    {REFER}
                  </span>
                )} */}
              </ul>
            );
          })}
        </nav>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href="https://www.bcorporation.net/en-us/find-a-b-corp/company/kotn/"
          className={styles.logo}
          target="_blank"
          rel="noopener"
        >
          <BCorpLogo />
        </a>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  navItems: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    menus: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            link: PropTypes.object,
            handle: PropTypes.string,
            type: PropTypes.string,
          })
        ).isRequired,
      })
    ),
  }),
  router: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

export default withRouter(Footer);
