import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Cta from "@components/Cta";
import { useCurrencyDispatch } from "@hooks/index";
import { AnnouncementBarLeft } from "./AnnouncementBarLeft";
import { AnnouncementBarRight } from "./AnnouncementBarRight";
import { resolveMarket } from "./lib/resolve-market";
import { DEFAULT_MESSAGE, CURRENCIES } from "./lib/constants";
import styles from "./announcementBar.module.css";

/*
  This function exists because feedonomics and google are forcing currency
  using both country param and currency param in url
*/
function resolveCountryFromParams(router) {
  const location = (router.basePath === "" ? "localhost:3000" : router.basePath) + router.asPath;
  const url = new URL(location);
  if (url.searchParams.get("country")) {
    const country = url.searchParams.get("country");
    if (country.toLowerCase() === "us") {
      return "USD";
    }
    return "CAD";
  }

  if (url.searchParams.get("currency")) {
    const currency = url.searchParams.get("currency");
    if (currency.toLowerCase() === "usd") {
      return "USD";
    }
    return "CAD";
  }

  return undefined;
}

function showAnnouncement(announcement) {
  let title = DEFAULT_MESSAGE;
  if (announcement.message) {
    title = announcement.message;
  }
  if (announcement.link) {
    return <Cta navLink={{ ...announcement.link, title }} />;
  }
  return <span>{typeof announce === "string" ? announcement : title}</span>;
}

export function AnnouncementBar({ announcement }) {
  const dispatch = useCurrencyDispatch();
  const router = useRouter();
  const [announce, setAnnouncement] = useState(announcement ?? DEFAULT_MESSAGE);

  function setLocation() {
    const location = JSON.parse(Cookies.get("location"));
    const market = resolveMarket(location);
    let override = [];
    if (announcement && announcement.marketOverride) {
      override = announcement.marketOverride.filter(
        (obj) => obj.market.toLowerCase() === market.toLowerCase()
      );
    }

    setAnnouncement(override[0] || announcement || DEFAULT_MESSAGE);
  }

  useEffect(() => {
    const getMarketByIp = async () => {
      const result = await fetch(`${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_API}/location`).then(
        (res) => res.json()
      );

      let country = "CA";
      if (result) {
        country = result.data.country;
        Cookies.set("location", JSON.stringify(result.data));
      }

      setLocation();
      if (!Cookies.get("cart_currency")) {
        switch (country) {
          case "US":
            Cookies.set("cart_currency", "USD");
            break;
          case "CA":
            Cookies.set("cart_currency", "CAD");
            break;
          case "AU":
            Cookies.set("cart_currency", "AUD");
            break;
          case "GB":
            Cookies.set("cart_currency", "GBP");
            break;
          default:
            Cookies.set("cart_currency", "CAD");
        }
        dispatch({ type: "set", currency: Cookies.get("cart_currency") });
      }
    };
    const currency = resolveCountryFromParams(router);

    if (currency) {
      dispatch({ type: "set", currency });
      Cookies.set("cart_currency", currency);
    } else if (!Cookies.get("cart_currency")) {
      getMarketByIp();
    } else {
      const currencyCookie = Cookies.get("cart_currency").toUpperCase();
      const exists = CURRENCIES.includes(currencyCookie);
      if (exists) {
        dispatch({ type: "set", currency: currencyCookie });
      }
    }

    if (Cookies.get("location")) {
      setLocation();
    } else {
      getMarketByIp();
    }
  }, []);

  return (
    <>
      <aside className={styles.wrapper}>
        <AnnouncementBarLeft />
        <div className={styles.announcement}>{showAnnouncement(announce)}</div>
        <AnnouncementBarRight />
      </aside>
    </>
  );
}

AnnouncementBar.propTypes = {
  announcement: PropTypes.shape({
    link: PropTypes.shape({
      link: PropTypes.shape({
        href: PropTypes.string,
        _type: PropTypes.string,
      }),
      handle: PropTypes.string,
      type: PropTypes.string,
      slug: PropTypes.shape({ current: PropTypes.string, _type: PropTypes.string }),
      productGroup: PropTypes.arrayOf(PropTypes.object),
    }),
    marketOverride: PropTypes.arrayOf(
      PropTypes.shape({
        market: PropTypes.string,
        message: PropTypes.string,
        link: PropTypes.shape({
          link: PropTypes.shape({
            href: PropTypes.string,
            _type: PropTypes.string,
          }),
          handle: PropTypes.string,
          type: PropTypes.string,
          slug: PropTypes.shape({ current: PropTypes.string, _type: PropTypes.string }),
          productGroup: PropTypes.arrayOf(PropTypes.object),
        }),
      })
    ),
    message: PropTypes.string,
  }).isRequired,
};
