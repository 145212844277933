import { withRouter } from "next/router";
import { NavBar } from "./Navigation";
import { Alert } from "./Alert";
import { AnnouncementBar } from "./AnnouncementBar";
import styles from "./styles/header.module.css";

function Header({ navigation, announcement, logo, brandStory, isPreview }) {
  return (
    <header className={styles.header}>
      {isPreview && <Alert />}
      <AnnouncementBar announcement={announcement} />
      <NavBar navigation={navigation} logo={logo} brandStory={brandStory} />
    </header>
  );
}

export default withRouter(Header);
