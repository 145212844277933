import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { NotFound } from "./NotFound";
import { Default } from "./Default";
import { Shared } from "./Shared";

export function Layout({
  config,
  seo = {},
  title,
  subscribed,
  children,
  isPreview = false,
  pageFound,
  modal,
}) {
  if (!config) {
    console.error("Missing config");
    return <div>Missing config</div>;
  }
  const router = useRouter();

  const { navigation, footer, logo, url } = config;
  const logoUrl = logo && logo.asset && logo.asset.url;

  if (router.isFallback) {
    return <div>Loading&hellip;</div>;
  }

  return (
    <>
      <Shared
        logoUrl={logoUrl}
        url={url}
        isPreview={isPreview}
        title={title}
        navigation={navigation}
        announcement={config.announcement}
        logo={logo}
        brandStory={config.brandStory}
      />
      {!router.isFallback && !pageFound ? (
        <NotFound footer={footer} />
      ) : (
        <Default seo={seo} url={url} subscribed={subscribed} footer={footer} modal={modal}>
          {children}
        </Default>
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string.isRequired,
  modal: PropTypes.oneOfType([
    PropTypes.shape({
      descriptionCopy: PropTypes.string.isRequired,
      thankYouCopy: PropTypes.string.isRequired,
      disclaimerCopy: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  seo: PropTypes.shape({
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
    twitterCard: PropTypes.string,
    openGraphImage: PropTypes.object,
  }),
  config: PropTypes.shape({
    title: PropTypes.string,
    brandStory: PropTypes.string,
    announcement: PropTypes.object,
    navigation: PropTypes.arrayOf(PropTypes.object),
    footer: PropTypes.object,
    logo: PropTypes.shape({
      asset: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    url: PropTypes.string,
  }).isRequired,
  pageFound: PropTypes.bool.isRequired,
  subscribed: PropTypes.bool,
  isPreview: PropTypes.bool,
};
