import { useRouter } from "next/router";
import DrawerLink from "./DrawerLink";
import styles from "@components/Typography/typography.module.css";
import { useMobileDetect } from "../../../hooks";
import drawerStyles from "./styles/drawer.module.css";

function DrawerMenu({ items }) {
  const router = useRouter();

  if (items.length === 0) return null;

  const isFrench = router.locale === "fr";

  return (
    <div>
      {items.menus &&
        items.menus.map((menu) => {
          const isEditorial = menu.links.some(item => item.type === "editorial");

          if (isFrench && !isEditorial) {
            return (
              <section className={drawerStyles.subMenuWrapper} key={menu.key}>
                <h3 className={drawerStyles.heading}>{menu.title}</h3>
                <ul className={drawerStyles.list}>
                  {menu.links &&
                    menu.links.map((link) => (
                      <li key={link.key}>
                        <DrawerLink navLink={link} />
                      </li>
                    ))}
                </ul>
              </section>
            )
          }

          if (!isFrench) {
            return (
              <section className={drawerStyles.subMenuWrapper} key={menu.key}>
                <h3 className={drawerStyles.heading}>{menu.title}</h3>
                <ul className={drawerStyles.list}>
                  {menu.links &&
                    menu.links.map((link) => (
                      <li key={link.key}>
                        <DrawerLink navLink={link} />
                      </li>
                    ))}
                </ul>
              </section>
            )
          }
      })}
    </div>
  );
}

export function Drawer({ navList, showDrawer, toggleDrawer, brandStory }) {
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  if (isMobile) {
    return null;
  }

  if (!navList) return null;

  const isAbout = navList.type === "index" && navList.slug?.current === "about";
  const drawerStyle = `${drawerStyles.wrapper} ${showDrawer ? drawerStyles.show : ""}`;

  return (
    <div
      className={drawerStyle}
      onMouseEnter={() => toggleDrawer(true)}
      onMouseLeave={() => toggleDrawer(false)}
    >
      {isAbout && <p className={styles.paragraph}>{brandStory}</p>}
      <DrawerMenu items={navList} />
    </div>
  );
}
