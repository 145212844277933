import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Button } from "@components/Button";
import { IconButton } from "./IconButton";
import { useMobileDetect } from "../../../hooks";
import { useCheckoutState, useCheckoutDispatch } from "@hooks/useCheckout";
import styles from "./styles/navbarright.module.css";
import { KotnSupplyQuoteButton } from "@components/KotnSupply/KotnSupplyQuoteDrawer";

function Mobile({ isMobile, isBagRoute }) {
  const { checkout } = useCheckoutState();
  let itemCount = 0;
  if (checkout) {
    itemCount = checkout.lineItems.edges.reduce((count, item) => {
      return count + item.node.quantity;
    }, 0);
  }
  const { toggleCart } = useCheckoutDispatch();
  if (!isMobile) return null;
  return (
    <>
      <Link href="/help" passHref prefetch={false}>
        <a>
          <IconButton showMobile size="3rem" title="Help">
            <svg xmlns="http://www.w3.org/2000/svg" height="18px" width="18px" viewBox="0 0 18 18">
              <g fill="none" fillRule="evenodd">
                <circle cx="9" cy="9" r="8.5" stroke="#191919"></circle>
                <path
                  fill="#191919"
                  d="M9.532 13.484H8.227v-1.507h1.305v1.507zm-.013-2.508H8.303c0-1.292.203-1.748.95-2.394.583-.52 1.178-1 1.178-1.887 0-.798-.519-1.28-1.431-1.28-.975 0-1.47.596-1.507 1.66H6.15c0-1.71 1.115-2.825 2.888-2.825 1.76 0 2.761 1.039 2.761 2.42 0 1.089-.671 1.849-1.342 2.393-.608.507-.938.938-.938 1.913z"
                ></path>
              </g>
            </svg>
          </IconButton>
        </a>
      </Link>
      <IconButton
        showMobile
        size="3rem"
        title="Cart"
        handleClick={() => !isBagRoute && toggleCart()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="18px" width="14px" viewBox="0 0 14 18">
          <g fill="none" fillRule="evenodd" stroke="#191919">
            <path d="M.5 4.5v11a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-11H.5zM3.25 4.5h7.5v-2a2 2 0 0 0-2-2h-3.5a2 2 0 0 0-2 2v2z"></path>
          </g>
        </svg>
        {itemCount !== 0 && <span className={styles.bagCount}>{itemCount}</span>}
      </IconButton>
    </>
  );
}
function Default({ isMobile, isBagRoute, currentPath }) {
  const { checkout } = useCheckoutState();
  const { openCart } = useCheckoutDispatch();
  const { locale } = useRouter();
  const { t } = useTranslation("navbar");

  const isFrench = locale === "fr";

  const checkoutItems = (checkout?.lineItems.edges ?? []).reduce(
    (sum, item) => sum + item.node.quantity,
    0
  );

  if (isMobile) return null;
  
  return (
<>
      { (/kotn-supply/.test(currentPath)) ? (
        <KotnSupplyQuoteButton />
      ) : (
      <>
      <div className={styles.link}>
        <Link href="/search" passHref prefetch={false}>
          <a>{t("search")}</a>
        </Link>
      </div>
      <div className={styles.link}>
        <Link href="/help" passHref prefetch={false}>
          <a>{t("help")}</a>
        </Link>
      </div>
      {!isFrench && (
        <div className={styles.link}>
          <Link href="/account" passHref prefetch={false}>
            <a>{t("account")}</a>
          </Link>
        </div>
      )}
      <div className={styles.link}>
        <a onClick={() => !isBagRoute && openCart()}>
          {t("bag")} {checkoutItems > 0 ? `(${checkoutItems})` : ""}
        </a>
      </div>
      </>
      )}
    </>
  );
}
export function NavBarRight() {
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      <Mobile 
        isMobile={isMobile} 
        isBagRoute={router.pathname === "/bag"} 
        currentPath={router.asPath}
      />
      <Default 
        isMobile={isMobile} 
        isBagRoute={router.pathname === "/bag"} 
        currentPath={router.asPath}
      />
    </div>
  );
}
