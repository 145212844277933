import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { SubscribeForm } from "./SubscribeForm";
import { DESCRIPTION, DISCLAIMER, THANKYOU } from "./lib/constants";
import { trackEvent } from "@lib/track-event";
import styles from "./styles/modal.module.css";
export function Modal({ description, thankYou, disclaimer }) {
  const [close, setClose] = useState(false);

  const [showSMSForm, setShowSMSForm] = useState(false);
  const [thankYouMessage, setthankYouMessage] = useState(false);

  useEffect(() => {
    if (!Cookies.get("modal_viewed")) {
      trackEvent("view_email_capture", { source: "modal" });
      Cookies.set("modal_viewed", "true", { expires: 1 });
    }
    const counter = Cookies.get("klaviyo_modal_viewed_counter");
    if (counter) {
      Cookies.set("klaviyo_modal_viewed_counter", parseInt(counter) + 1, { expires: 183 });
    } else {
      Cookies.set("klaviyo_modal_viewed_counter", 1, { expires: 183 });
    }
  }, []);

  useEffect(() => {
    if (thankYouMessage) {
      const timer = setTimeout(() => {
        setClose(true); // count is 0 here
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [thankYouMessage]);

  function closeModal() {
    if (!Cookies.get("modal_closed")) {
      Cookies.set("modal_closed", "true", { expires: 5 });
      Cookies.remove("klaviyo_modal_viewed_counter");
    }
    setClose(true);
  }

  function toggleForm() {
    setShowSMSForm(!showSMSForm);
  }

  const containerStyles = `${styles.container} ${close ? styles.close : ""} ${
    close ? styles.subscribed : ""
  }`;
  const split = description.split("\n");
  return (
    <div className={containerStyles}>
      <span className={styles.header} onClick={closeModal}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M1 13L13 1M13 13L1 1" stroke="black" strokeWidth="1.5" />
        </svg>
      </span>
      <section className={styles.body}>
        {!thankYouMessage && (
          <div className={styles.description}>
            {split.map((sentence, index) => {
              if (index === 0 && split.length > 1) {
                return <p className={styles.bold}>{sentence}</p>;
              }
              return <p>{sentence}</p>;
            })}
          </div>
        )}
        {thankYouMessage && (
          <div className={styles.thankyou}>
            <p>{thankYou}</p>
          </div>
        )}
        <SubscribeForm
          setthankYouMessage={setthankYouMessage}
          toggleForm={toggleForm}
          isSMS={showSMSForm}
          disclaimer={disclaimer}
        />
      </section>
    </div>
  );
}

Modal.propTypes = {
  description: PropTypes.string,
  thankYou: PropTypes.string,
  disclaimer: PropTypes.string,
};

Modal.defaultProps = {
  description: DESCRIPTION,
  thankYou: THANKYOU,
  disclaimer: DISCLAIMER,
};
