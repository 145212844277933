import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { getOpenGraph } from "@lib/getOpenGraph";
import { Newsletter } from "./Newsletter";
import { CartDrawer } from "@components/Cart";
import { KotnSupplyQuoteDrawer } from "@components/KotnSupply/KotnSupplyQuoteDrawer";
import Footer from "./Footer";

const STORE_HUBS = ["/mtr-emails", "/la-emails", "/van-emails", "/to-emails"];

function isStoreHub(path) {
  return STORE_HUBS.includes(path);
}

export function Default({ seo, title, url, children, modal, subscribed, footer }) {
  const { seoTitle, seoDescription, openGraphImage, twitterCard } = seo;
  const openGraphImages = getOpenGraph(openGraphImage, title);
  const router = useRouter();
  const hideModal = isStoreHub(router.asPath);

  let canonical = null;
  if (url) {
    canonical = url + router.asPath;
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_STAGING === "true" ? (
        <NextSeo noindex />
      ) : (
        <NextSeo
          title={seoTitle}
          titleTemplate="%s"
          description={seoDescription}
          canonical={canonical}
          openGraph={{
            type: "website",
            url: canonical,
            title: `${seoTitle}`,
            description: `${seoDescription}`,
            images: openGraphImages,
            site_name: "Kotn",
          }}
          twitter={{
            handle: "@kotnbasics",
            site: "@kotnbasics",
            cardType: `${twitterCard}`,
          }}
        />
      )}

      {children}
      <CartDrawer />
      <KotnSupplyQuoteDrawer />
      <Newsletter modal={modal} subscribed={subscribed || hideModal} />
      <Footer navItems={footer} />
    </>
  );
}
